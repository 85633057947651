/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "jodit/build/jodit.min.css";
import JoditVue from "jodit-vue";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
// import fs from "fs"
Vue.use(JoditVue);
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  // extract,
  // unzip,
  // saveAs,
  // JSZip,
  render: (h) => h(App),
}).$mount("#app");
